import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Link } from 'gatsby'

import { widowFix } from 'utils/string'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'
import { black } from 'styles/colors'
import { square } from 'styles/helpers'
import { borderRadius } from 'styles/variables'

import Heading from 'components/Heading'
import Icon from 'components/Icon'
import RichText from 'components/RichText'

const Container = styled(Link)`
  display: flex;
  padding: ${spacing.small};
  margin: -${spacing.small};
`

const IconWrap = styled.div`
  flex-shrink: 0;
  color: ${({ theme }) => theme.heading};
  margin-right: ${spacing.medium};

  ${largerThan.small`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${borderRadius};
    background-color: ${rgba(black, 0.15)};
    ${square('40px')};
  `};

  ${largerThan.medium`
    margin-right: ${spacing.scale(spacing.medium, 1.25)};
    ${square('48px')};
  `};

  ${largerThan.large`
    margin-top: 1px; /* Align icon with heading */
  `};

  ${largerThan.xLarge`
    margin-right: ${spacing.large};
  `};
`

const Content = styled.div`
  flex: 1;
`

const ServiceItem = ({ slug, icon, name, description }) => (
  <Container to={`/services/${slug}/`}>
    <IconWrap>
      <Icon icon={icon} />
    </IconWrap>
    <Content>
      <Heading size={4} tag="p">
        {name}
      </Heading>
      <RichText>
        <p>{widowFix(description)}</p>
      </RichText>
    </Content>
  </Container>
)

export default ServiceItem
