import React from 'react'
import styled from 'styled-components'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import LogoMarquee from 'components/LogoMarquee'

const logos = [
  'affirm',
  'aldo',
  'bat',
  'beamery',
  'bell',
  'consensys',
  'creditkarma',
  'desjardins',
  'plenty',
  'qbio',
  'salesforce',
  'seatgeek',
  'sentilink',
  'titan',
  'truework',
  'yellow-pages',
  'zendesk',
]

const Container = styled.div`
  overflow-x: hidden;
`

const Clients = () => (
  <Container>
    <Section flushTop>
      <Wrapper>
        <LogoMarquee logos={logos} />
      </Wrapper>
    </Section>
  </Container>
)

export default Clients
