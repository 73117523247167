import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { stripUnit, rgba } from 'polished'

import { INDUSTRIES, SERVICES } from 'utils/constants'

import * as spacing from 'styles/spacing'
import { largerThan, smallerThan } from 'styles/media'
import { themes } from 'styles/theme'
import { black } from 'styles/colors'

import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'
import Button from 'components/button/Button'
import RichText from 'components/RichText'
import { Grid, GridRow, GridColumn } from 'components/Grid'
import IndustryCard from 'components/industries/IndustryCard'
import ServiceItem from './ServiceItem'

const Inner = styled.div`
  background: linear-gradient(
    180deg,
    ${rgba(black, 0)} 0%,
    ${rgba(black, 0.15)} 100%
  );
`

const Main = styled.div`
  margin-bottom: ${spacing.medium};

  ${largerThan.small`
    margin-bottom: ${stripUnit(spacing.medium) * 1.25 + 'rem'};
  `};

  ${largerThan.medium`
    margin-bottom: 0;
    padding-right: ${spacing.xxLarge};
  `};

  ${smallerThan.medium`
    max-width: 680px;
  `};

  ${largerThan.large`
    padding-right: 17.5%;
  `};
`

const StyledHeading = styled(Heading)`
  position: static;

  ${largerThan.large`
    margin-bottom: ${spacing.large};
  `};
`

const Description = styled.div`
  margin-bottom: ${spacing.large};

  ${largerThan.medium`
    margin-bottom: ${spacing.xLarge};
  `};

  ${largerThan.xLarge`
    margin-bottom: ${spacing.xxLarge};
  `};
`

const List = styled.div`
  display: grid;
  grid-gap: ${spacing.large};

  ${smallerThan.medium`
    max-width: 680px;
  `};

  ${largerThan.large`
    grid-gap: ${spacing.xLarge};
    padding-right: 10%;
  `};

  ${largerThan.xLarge`
    grid-gap: ${spacing.xxLarge};
  `};
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.border};
  height: 1px;
`

const Industries = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.medium};

  ${largerThan.small`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${largerThan.medium`
    grid-template-columns: repeat(4, 1fr);
  `};

  ${largerThan.large`
    grid-template-columns: repeat(6, 1fr);
  `};
`

const Services = () => (
  <ThemeProvider theme={themes.dark}>
    <Inner>
      <Section>
        <Wrapper>
          <SectionBlock>
            <Grid>
              <GridRow>
                <GridColumn xs={12} md={6}>
                  <Main>
                    <StyledHeading size={2}>
                      A one-stop shop for all things digital product development
                    </StyledHeading>
                    <Description>
                      <RichText>
                        <p>
                          We work as an extension of your team and can take a
                          product idea from inception to launch.
                        </p>
                      </RichText>
                    </Description>
                    <Button to="/services/">View our services</Button>
                  </Main>
                </GridColumn>
                <GridColumn xs={12} md={6}>
                  <List>
                    {SERVICES.map((service, i) => (
                      <ServiceItem
                        slug={service.slug}
                        description={service.description}
                        name={service.name}
                        icon={service.icon}
                        key={i}
                      />
                    ))}
                  </List>
                </GridColumn>
              </GridRow>
            </Grid>
          </SectionBlock>
          <SectionBlock>
            <Divider />
          </SectionBlock>
          <SectionBlock>
            <Industries>
              {INDUSTRIES.map((industry, i) => (
                <IndustryCard
                  key={i}
                  name={industry.name}
                  slug={industry.slug}
                  photo={industry.photo}
                  size="small"
                />
              ))}
            </Industries>
          </SectionBlock>
        </Wrapper>
      </Section>
    </Inner>
  </ThemeProvider>
)

export default Services
