import React from 'react'
import styled from 'styled-components'

import { widowFix } from 'utils/string'

import { largerThan } from 'styles/media'
import { fontFamilies, fontWeights, lineHeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'

const Container = styled.div`
  padding-bottom: ${spacing.large};

  ${largerThan.small`
    padding-bottom: ${spacing.xLarge};
  `};

  ${largerThan.medium`
    padding-bottom: ${spacing.xxLarge};
  `};

  ${largerThan.xLarge`
    padding-bottom: 3rem;
  `};
`

const Inner = styled.div`
  max-width: 28rem;

  ${largerThan.small`
    max-width: 30rem;
  `};

  ${largerThan.medium`
    max-width: 38rem;
  `};

  ${largerThan.large`
    max-width: 46rem;
  `};

  ${largerThan.xLarge`
    max-width: 50rem;
  `};
`

const Title = styled(Heading)`
  margin-bottom: ${spacing.medium};

  ${largerThan.medium`
    margin-bottom: ${spacing.large};
  `};
`

const Subheading = styled(Heading)`
  color: ${({ theme }) => theme.body};
  font-family: ${fontFamilies.body};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.base};
  margin-bottom: ${spacing.large};

  ${largerThan.medium`
    margin-bottom: ${spacing.xLarge};
  `};

  ${largerThan.large`
    max-width: 40rem;
  `};

  ${largerThan.xLarge`
    max-width: 44rem;
  `};
`

const Hero = () => (
  <Container>
    <Section>
      <Wrapper>
        <Inner>
          <Title size={1}>Accelerating your digital transformation</Title>
          <Subheading size={4} tag="h2">
            {widowFix(
              'Volume7 is a product-focused software development agency for enterprise & high-growth companies.'
            )}
          </Subheading>
          <ButtonGroup>
            <Button to="/work/">View our work</Button>
            <Button to="/about/" intent="tertiary">
              About us
            </Button>
          </ButtonGroup>
        </Inner>
      </Wrapper>
    </Section>
  </Container>
)

export default Hero
