import React from 'react'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import ActionBlock from 'components/ActionBlock'
import { Grid, GridRow, GridColumn } from 'components/Grid'

import Smiley from 'assets/icons/smiley.svg'
import Bag from 'assets/icons/bag.svg'

const Spacer = styled.div`
  width: 100%;
  height: ${spacing.small};

  ${largerThan.small`
    height: ${spacing.scale(spacing.medium, 1.25)};
  `};

  ${largerThan.medium`
    display: none;
  `};
`

const Actions = () => (
  <Section>
    <Wrapper>
      <Grid>
        <GridRow>
          <GridColumn xs={12} md={6}>
            <ActionBlock
              icon={<Bag />}
              title="Want to partner with us?"
              description="We work with companies small and large who are looking to double down on their digital initiatives."
              link="/contact/"
              actionLabel="Contact us"
            />
          </GridColumn>
          <Spacer />
          <GridColumn xs={12} md={6}>
            <ActionBlock
              icon={<Smiley />}
              title="Want to join our team?"
              description="We're looking for hard-working and passionate self-starters to help us build outstanding digital products."
              link="/careers/"
              actionLabel="View openings"
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Wrapper>
  </Section>
)

export default Actions
