import React from 'react'
import styled, { css } from 'styled-components'
import { stripUnit, rgba, mix } from 'polished'
import { Link } from 'gatsby'

import { borderRadius, animationTimes, animationCurve } from 'styles/variables'
import { cover } from 'styles/helpers'
import { white, black } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'

import Button from 'components/button/Button'
import Heading from 'components/Heading'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${spacing.scale(borderRadius, 1.25)};
  background-color: ${(props) => mix(0.15, props.color, white)};
  transform: translate3d(0, 0, 0);

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}

  ${largerThan.small`
    flex-direction: row;
  `};

  ${largerThan.medium`
    flex-direction: column;
  `};
`

const LogoWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${spacing.large};

  ${largerThan.medium`
    height: 37px;
  `};

  ${largerThan.large`
    height: 40px;
    margin-bottom: ${spacing.xLarge};
  `};
`

const Logo = styled.div`
  display: flex;
  width: ${(props) => Math.round(stripUnit(props.width) * 0.7) + 'px'};

  ${largerThan.small`
    width: ${(props) => Math.round(stripUnit(props.width) * 0.8) + 'px'};
  `};

  ${largerThan.large`
    width: ${(props) => Math.round(stripUnit(props.width) * 0.9) + 'px'};
  `};

  ${largerThan.xLarge`
    width: ${(props) => Math.round(stripUnit(props.width) * 1) + 'px'};
  `};

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: ${spacing.large};
  margin-top: -3.5rem;

  ${largerThan.small`
    margin-top: 0;
    margin-left: -6rem;
    padding: ${spacing.xLarge};
  `};

  ${largerThan.medium`
    margin-top: -4rem;
    margin-left: 0;
  `};

  ${largerThan.large`
    padding: ${spacing.xxLarge};
    margin-top: -6rem;
  `};

  ${largerThan.xLarge`
    padding: 3rem;
    margin-top: -6.5rem;
  `};
`

const Header = styled.div`
  position: relative;
  height: 140px;

  ${largerThan.small`
    height: 180px;
    height: auto;
    width: 50%;
    flex-shrink: 0;
  `};

  ${largerThan.medium`
    height: 220px;
    width: 100%;
  `};

  ${largerThan.large`
    height: 260px;
  `};

  ${largerThan.xLarge`
    height: 280px;
  `};
`

const FigureWrap = styled.div`
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  ${cover('absolute')};

  > * {
    opacity: 0.95;
    filter: contrast(0.85);
    transition: transform ${animationTimes.base} ${animationCurve};
    ${cover('absolute')};

    ${Container}:hover & {
      transform: scale(1.05);
    }
  }
`

const Overlay = styled.div`
  background-image: linear-gradient(
    0deg,
    ${(props) => mix(0.15, props.color, white)} 0%,
    ${(props) => rgba(mix(0.15, props.color, white), 0)} 100%
  );
  z-index: 10;
  ${cover('absolute')};

  ${largerThan.small`
    background-image: linear-gradient(-90deg,
    ${(props) => mix(0.15, props.color, white)} 0%,
    ${(props) => rgba(mix(0.15, props.color, white), 0)} 100%
  );
  `};

  ${largerThan.medium`
    background-image: linear-gradient(
    0deg,
    ${(props) => mix(0.15, props.color, white)} 0%,
    ${(props) => rgba(mix(0.15, props.color, white), 0)} 100%
  );
  `};
`

const Title = styled(Heading)`
  color: ${black};
  margin-bottom: ${spacing.small};

  ${largerThan.large`
    margin-bottom: ${spacing.scale(spacing.small, 1.5)};
    padding-right: ${spacing.small};
  `};
`

const Description = styled.p`
  flex: 1; /* Align button to bottom */
  color: ${(props) => rgba(mix(0.3, props.color, black), 0.8)};
  margin-bottom: ${spacing.large};

  ${largerThan.large`
    margin-bottom: ${spacing.xLarge};
    padding-right: ${spacing.medium};
  `};

  ${largerThan.xLarge`
    margin-bottom: ${spacing.xxLarge};
  `};
`

const Tag = styled(Heading)`
  display: inline-block;
  padding: 6px ${spacing.small} 5px;
  color: ${white};
  position: absolute;
  top: ${spacing.large};
  left: ${spacing.large};
  z-index: 10;
  pointer-events: none;
  background-color: ${rgba(black, 0.35)};
  backdrop-filter: blur(10px);
  border-radius: ${spacing.scale(borderRadius, 0.75)};
  margin: 0;

  ${largerThan.small`
    top: ${spacing.xLarge};
    left: ${spacing.xLarge};
  `};

  ${largerThan.medium`
    border-radius: ${borderRadius};
    padding: 7px ${spacing.scale(spacing.small, 1.5)} 6px;
  `};

  ${largerThan.large`
    top: ${spacing.xxLarge};
    left: ${spacing.xxLarge};
    padding-top: 8px;
    padding-bottom: 7px;
  `};

  ${largerThan.xLarge`
    top: 3rem;
    left: 3rem;
  `};
`

const CasePreview = ({
  color,
  logo,
  logoWidth,
  slug,
  figure,
  heading,
  tag,
  description,
  isDisabled,
}) => (
  <Container
    color={color}
    as={isDisabled ? 'div' : Link}
    to={`/work/${slug}/`}
    isDisabled={isDisabled}
  >
    <Header>
      {!!tag && (
        <Tag size={6} tag="span">
          {tag}
        </Tag>
      )}
      <FigureWrap>{figure}</FigureWrap>
      <Overlay color={color} />
    </Header>
    <Content>
      <LogoWrap>
        <Logo width={logoWidth}>{logo}</Logo>
      </LogoWrap>
      <Title size={4} tag="p">
        {heading}
      </Title>
      <Description color={color}>{description}</Description>
      <Button
        intent="custom"
        customcolor={color}
        as="span"
        disabled={isDisabled}
      >
        View case study
      </Button>
    </Content>
  </Container>
)

export default CasePreview
