import React from 'react'
import styled from 'styled-components'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Testimonial from 'components/Testimonial'
import CasePreview from 'components/CasePreview'
import { Grid, GridRow, GridColumn } from 'components/Grid'

const TestimonialWrap = styled.div`
  margin-top: ${spacing.small};

  ${largerThan.small`
    margin-top: ${spacing.scale(spacing.medium, 1.25)};
  `};

  ${largerThan.medium`
    padding-left: ${spacing.large};
  `};

  ${largerThan.large`
    padding-left: ${spacing.xxLarge};
  `};

  ${largerThan.xLarge`
    padding-left: 64px;
  `};
`

const FeaturedProject = () => {
  const handle = INFO_FOR_PROJECT_NAME['aldo']

  return (
    <Section hasBorder>
      <Wrapper>
        <Grid>
          <GridRow align="center">
            <GridColumn xs={12} md={6}>
              <CasePreview
                color={handle.color}
                logo={handle.logo}
                logoWidth={handle.logoWidth}
                heading={handle.heading}
                figure={handle.figure}
                description={handle.description}
                slug={handle.slug}
                tag="Featured project"
              />
            </GridColumn>
            <GridColumn xs={12} md={6}>
              <TestimonialWrap>
                <Testimonial
                  name="Ysaël Pépin"
                  role="IT Development Manager, Aldo"
                  quote="We chose Volume7 as our digital innovation partner because of their multidisciplinary offering and ability to deliver quickly."
                  alignment="left"
                />
              </TestimonialWrap>
            </GridColumn>
          </GridRow>
        </Grid>
      </Wrapper>
    </Section>
  )
}

export default FeaturedProject
