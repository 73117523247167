import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Link } from 'gatsby'

import { square } from 'styles/helpers'
import { borderRadius } from 'styles/variables'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import Icon from 'components/Icon'

const Container = styled.div`
  position: static;

  ${largerThan.small`
    padding-right: ${spacing.large};
    max-width: 700px;
  `};

  ${largerThan.medium`
    padding-right: 10%;
    max-width: none;
  `};

  ${largerThan.large`
    padding-right: 15%;
  `};
`

const IconWrap = styled.div`
  display: flex;
  margin-bottom: ${spacing.scale(spacing.small, 1.5)};
  color: ${({ theme }) => theme.heading};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => rgba(theme.heading, 0.075)};
  border-radius: ${borderRadius};
  ${square('40px')};

  ${largerThan.medium`
    margin-bottom: ${spacing.medium};
    ${square('48px')};
  `};

  ${largerThan.large`
    margin-bottom: ${spacing.large};
  `};
`

const Title = styled(Heading)`
  margin-bottom: ${spacing.small};

  ${largerThan.medium`
    margin-bottom: ${spacing.scale(spacing.small, 1.5)};
  `};
`

const Description = styled.p`
  margin-bottom: 0 !important;

  & + & {
    margin-top: ${spacing.medium};

    ${largerThan.medium`
      margin-top: ${spacing.large};
    `};
  }
`

const ActionBlock = ({ icon, title, description, link, actionLabel }) => (
  <Container>
    <IconWrap>
      <Icon icon={icon} />
    </IconWrap>
    <Title size={4} tag="h2">
      {title}
    </Title>
    <RichText>
      <Description>{description}</Description>
      <Description>
        <Link to={link}>{actionLabel}</Link>
      </Description>
    </RichText>
  </Container>
)

export default ActionBlock
