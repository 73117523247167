import React from 'react'

import MetaTags from 'components/MetaTags'
import Hero from 'components/home/Hero'
import FeaturedProject from 'components/home/FeaturedProject'
import Clients from 'components/home/Clients'
import Actions from 'components/home/Actions'
import Services from 'components/home/Services'

const Home = () => (
  <>
    <MetaTags title="Custom software development in Montreal | Volume7" />
    <Hero />
    <Clients />
    <FeaturedProject />
    <Services />
    <Actions />
  </>
)

export default Home
