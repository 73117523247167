import React, { useContext } from 'react'
import Marquee from 'react-fast-marquee'

import styled, { css, ThemeContext } from 'styled-components'
import { stripUnit } from 'polished'

import { INFO_FOR_PROJECT_NAME } from 'utils/constants'

import { themes } from 'styles/theme'
import * as spacing from 'styles/spacing'
import { largerThan } from 'styles/media'
import { white } from 'styles/colors'

const Container = styled.div`
  margin-left: -${spacing.large};
  margin-right: -${spacing.large};
  -webkit-mask-image: linear-gradient(
    90deg,
    ${white},
    ${white} 0,
    transparent 0,
    ${white} 20%,
    ${white} calc(100% - 20%),
    transparent
  );

  ${largerThan.small`
    margin-left: -${spacing.xxLarge};
    margin-right: -${spacing.xxLarge};
  `};

  ${largerThan.medium`
    margin-left: -${spacing.xxxLarge};
    margin-right: -${spacing.xxxLarge};
  `};
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 0 ${spacing.scale(spacing.medium, 1.5)};
  width: ${(props) => Math.round(stripUnit(props.width) * 0.7) + 'px'};

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${(props) =>
    props.dark &&
    css`
      *:not(.nofill) {
        fill: ${white};
      }
    `}

  ${largerThan.small`
    margin: 0 ${spacing.large};
  `};

  ${largerThan.medium`
    margin: 0 ${spacing.scale(spacing.large, 1.5)};
    width: ${(props) => Math.round(stripUnit(props.width) * 0.8) + 'px'};
  `};

  ${largerThan.large`
    margin: 0 ${spacing.xLarge};
    width: ${(props) => Math.round(stripUnit(props.width) * 0.9) + 'px'};
  `};

  ${largerThan.xLarge`
    margin: 0 ${spacing.scale(spacing.xLarge, 1.25)};
    width: ${(props) => Math.round(stripUnit(props.width) * 1) + 'px'};
  `};
`

const LogoMarquee = ({ logos }) => {
  const themeContext = useContext(ThemeContext)

  return (
    <Container>
      <Marquee gradient={false}>
        {logos.map((logo, i) => {
          const handle = INFO_FOR_PROJECT_NAME[logo]

          return (
            <Item
              key={i}
              width={handle.logoWidth}
              dark={themeContext === themes.dark}
            >
              {handle.logo}
            </Item>
          )
        })}
      </Marquee>
    </Container>
  )
}

export default LogoMarquee
