import React from 'react'
import styled, { css } from 'styled-components'

import { widowFix } from 'utils/string'

import { primaryColor } from 'styles/colors'
import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'

import FancyQuote from 'assets/images/about/quote.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;

  ${(props) =>
    props.alignment === 'left' &&
    css`
      text-align: left;
      align-items: flex-start;
    `}
`

const Figure = styled(FancyQuote)`
  width: 24px;
  height: auto;
  fill: ${primaryColor};

  ${largerThan.small`
    width: 28px;
  `};

  ${largerThan.medium`
    width: 32px;
  `};

  ${largerThan.large`
    width: 36px;
  `};

  ${largerThan.xLarge`
    width: 40px;
  `};
`

const Quote = styled(Heading)`
  margin: 0.75em 0;
  text-align: center;

  ${(props) =>
    props.alignment === 'left' &&
    css`
      text-align: left;
    `}
`

const Footer = styled.div`
  text-align: center;

  ${(props) =>
    props.alignment === 'left' &&
    css`
      text-align: left;
    `}
`

const Name = styled(Heading)`
  display: block;
  margin-bottom: 0;

  ${(props) =>
    props.blurred &&
    css`
      user-select: none;
      filter: blur(6px);
    `}

  ${largerThan.medium`
    margin-bottom: ${spacing.tiny};
  `};
`

const Role = styled.span`
  display: block;
  color: ${({ theme }) => theme.body};
`

const Testimonial = ({
  quote,
  name,
  role,
  alignment = 'center',
  isAnonymized,
}) => (
  <Container alignment={alignment}>
    <Figure />
    <Quote size={2} tag="p" alignment={alignment}>
      {widowFix(quote)}
    </Quote>
    <Footer alignment={alignment}>
      {(name || isAnonymized) && (
        <Name tag="span" size={5} blurred={isAnonymized}>
          {isAnonymized ? 'Anonymous Name' : name}
        </Name>
      )}
      <Role>{role}</Role>
    </Footer>
  </Container>
)

export default Testimonial
